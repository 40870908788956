<template>
  <!-- 新闻页面 -->
  <div style="padding: 24px 0 38px 0">
    <div class="project-detail">
      <div class="close" title="关闭" @click="close">x</div>
      <p class="news-title">{{ data.title }}</p>
      <p class="news-source">
        <span>创建时间：{{ data.createTime }}</span>
        <span>文章作者：{{ data.author }}</span>
      </p>
      <div class="content">
        <article class="markdown-body" v-html="data.content"></article>

        <div v-if="checkChineseCharacters(data.content) == ''">
          <div class="content" v-for="page in pageAllNum" :key="page">
            <pdf :src="src" :page="page"></pdf>
          </div>
        </div>
<br>
        <div class="download" v-if="files && files.length > 0">
          <span class="label">附件下载：</span>
          <span
            class="download-btn"
            v-for="(item, index) in files"
            :key="index"
            title="下载"
            @click="download(item)"
          >
            {{ item.name }}
            <i class="el-icon-download" @click="download(item)"></i>
          </span><br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf-signature";
// import CMapReaderFactory from "vue-pdf-signature/src/CMapReaderFactory.js";
import "github-markdown-css/github-markdown.css";
import { getNewsDetail } from "../../api/development";
import { download } from "@/api/add";
import { log } from "console";
export default {
  components: {
    pdf,
  },
  data() {
    return {
      info: {},
      content: "",
      data: {},
      pdfUrl: "",
      files: [],
      src: "", // pdf文件src
      pageAllNum: 0, // pdf文件总页数
    };
  },
  created() {
    this.initData();
  },

  methods: {
    checkChineseCharacters(content) {
      const hasChinese = /[\u4e00-\u9fa5]/.test(content);
      return hasChinese ? content : "";
    },
    async contractInfo() {
      console.log(this.files);

      let res = await download({ ossFilePath: this.files[0].url });
      if (res) {
        const blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
        this.src = window.URL.createObjectURL(blob);
        this.getNumPages(this.src);
        // this.src = pdf.createLoadingTask({
        //   url: src,
        // 	CMapReaderFactory,
        // });
      }
    },
    // 获取PDF页数
    getNumPages(data) {
      let loadingTask = pdf.createLoadingTask(data);
      loadingTask.promise
        .then((pdf) => {
          console.log("pdf", pdf);

          this.pageAllNum = pdf.numPages;
        })
        .catch((err) => {
          console.error("pdf 加载失败", err);
        });
    },

    async initData() {
      // 加载新闻
      let params = {
        id: this.$route.query.c,
      };
      console.log(params);
      let res = await getNewsDetail(params);
      let { code, data } = res;
      if (code == 0) {
        this.data = data;
        if (data.attachFileUrl) {
          this.files = data.attachFileUrl.split("?");
          this.files = this.files.map((x) => {
            return JSON.parse(x);
          });
        }
      }
      this.contractInfo();
    },
    close() {
      this.$router.go(-1);
    },
    async download(data) {
      let res = await download({ ossFilePath: data.url });
      if (res) {
        let blob = res;
        const fileName = data.name;
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/pdf" })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.project-detail {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  text-align: left;
  padding-top: 12px;
  padding-bottom: 78px;
  position: relative;
  .close {
    width: 16px;
    height: 16px;
    position: absolute;
    font-size: 24px;
    right: 12px;
    top: 8px;
    color: #666666;
    cursor: pointer;
  }
  .news-title {
    text-align: center;
    font-size: 18px;
    padding: 10px 0;
    margin-top: 12px;
  }
  .news-source {
    text-align: center;
    color: #666666;
    padding: 0 0 12px 0;
    font-size: 12px;
    border-bottom: 1px solid #ddd;
    span {
      margin-right: 20px;
    }
  }
  .content {
    padding: 20px 38px;
    min-height: 500px;
    position: relative;
    p {
      img {
        text-align: center;
      }
    }
  }
  a {
    text-decoration: none;
  }
  a:link {
    color: #3e57dd;
  }
  a:visited {
    /*浏览过的*/
    color: #3e57dd;
  }
  a:hover {
    /*悬浮状态*/
    color: #3e57dd;
  }
  a:active {
    /*激活过的*/
    color: red;
  }
}
.download {
  position: relative;
  bottom: 0;
  left: 20px;
  display: flex;
	flex-direction: column; /* 垂直排列 */
  gap: 10px;
}
.download-btn {
  display: inline-block;
  cursor: pointer;
  background-color: #ededed;
  color: #409eff;
  padding: 4px 8px;
  i {
    margin-left: 20px;
  }
}
</style>
